import Vue from "vue"
import App from "./App.vue"
import Routes from "../router"
import VueRouter from "vue-router"

Vue.config.productionTip = false
Vue.use(VueRouter)
const router = new VueRouter({
	routes: Routes,
	mode: "history",
})

new Vue({
	el: "#app",
	components: { App },
	router,
	render: (h) => h(App),
	template: "<App/>",
})

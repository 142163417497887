<template>
	<div id="app">
		<router-view :key="$route.fullPath" />
	</div>
</template>

<script>
export default {
	name: "App",
	components: {},
}
</script>

<style>
#app {
	margin: 0;
	padding: 0;
	width: 100%;
}
</style>

export default [
	{
		path: "/",
		component: () => import("@/views/HomePage.vue"),
	},
	//make a catch all route to redirect to home page
	{
		path: "*",
		redirect: "/",
	},
]
